<template>
  <MPopover
    ref="dropdownPopoverRef"
    :placement="popoverPlacment"
    :get-popup-container="popupContainer"
    v-bind="$attrs"
    :disabled="disabled"
    transition-name="slide-up"
    overlay-class-name="picker-overlay"
    @hide="handleHide"
    @show="handleShow"
    v-on="listeners"
  >
    <template v-slot:trigger>
      <slot
        name="trigger"
        :hide="handleHide"
        :show="handleShow"
        :toggle="toggleDropdown"
        :current-item="selectedItem"
        :is-open="isDropdownOpen"
      >
        <MultipleTrigger
          v-if="multiple"
          :toggle="toggleDropdown"
          :allow-clear="allowClear"
          :focus-event-brodcast="focusEventBrodcast"
          :selected-items="selectedItemFormMultiple"
          :disabled="disabled"
          :as-tag="asTag"
          v-bind="attrs"
          :input-classes="inputClasses"
          :placeholder="placeholder"
          :options="innerOptions"
          @change="handleChange"
        />
        <DropdownTrigger
          v-else
          :toggle="toggleDropdown"
          :focus-event-brodcast="focusEventBrodcast"
          :selected-item="selectedItem"
          :is-open="isDropdownOpen"
          :allow-clear="allowClear"
          :text-only="textOnly"
          :disabled="disabled"
          :as-input="asInput"
          :size="size"
          v-bind="attrs"
          :input-classes="inputClasses"
          :placeholder="placeholder || $t('select')"
          @reset="handleChange(undefined)"
        />
      </slot>
    </template>
    <div class="flex flex-col h-100 min-h-0">
      <div ref="scrollContainer" class="flex h-100 min-h-0 flex-col">
        <slot name="before-menu">
          <div v-if="searchable || searchFn" class="my-2 px-2">
            <MInput
              ref="searchBox"
              v-model="searchQuery"
              :placeholder="$t('search')"
              @update="searchItems"
            >
              <template v-slot:suffix>
                <MIcon
                  v-if="loadingOptions"
                  name="spinner-third"
                  class="fa-spin text-neutral-light"
                />
                <MIcon v-else name="search" />
              </template>
            </MInput>
          </div>
          <div
            v-if="multiple && selectedItemFormMultiple.length"
            :class="{
              'mt-2': !(searchable || searchFn),
              'mt-0': searchable || searchFn,
              'px-2': true,
              'mb-2': true,
              'dropdown-selected-items-container':
                selectedItemFormMultiple.length > 4,
            }"
          >
            <div class="text-primary mb-1">
              {{ $tc('selected') }} {{ $tc('item', 2) }}
            </div>
            <MultipleTrigger
              :allow-clear="false"
              :selected-items="selectedItemFormMultiple"
              disabled
              as-tag
              can-remove-selected-items-pill
              display-all-selected-items-pill
              :options="innerOptions"
              :as-input="false"
              v-bind="attrs"
              :input-classes="inputClasses"
              @change="handleChange"
            />
            <MDivider class="mb-0 mt-1" />
          </div>
          <slot name="suggested-item"></slot>
        </slot>
        <slot name="menu">
          <FlotoScrollView>
            <slot>
              <MMenu
                v-if="optionsWithoutArchived.length"
                ref="menuContainer"
                :class="menuClass"
              >
                <MMenuItem
                  v-for="(item, index) in optionsToDisplay"
                  :key="String(item.id || item.value || item.key)"
                  :class="{
                    'scroll-dropdown-menu-item': true,
                    [menuItemClass]: true,
                    [menuItemSelectedClass]: index === currentIndex,
                    [menuItemDisabledClass]: item.disabled,
                    'value-active-item': Array.isArray(value)
                      ? value.indexOf(item.key) >= 0
                      : value === item.key,
                  }"
                >
                  <slot name="menu-item" :item="item" :select-item="selectItem">
                    <div
                      class="dropdown-item flex items-center"
                      :class="{ 'font-semibold': searchQuery }"
                      href="javascript:;"
                      @click.stop="selectItem(item)"
                    >
                      <FlotoDot v-if="item.color" :bg="item.color" />
                      <span class="mx-1 text-ellipsis" style="display: block">
                        {{ item.text || item.name || item.label }}
                      </span>
                    </div>
                  </slot>
                </MMenuItem>
              </MMenu>
              <slot
                v-if="showNoData && optionsToDisplay.length <= 0"
                name="no-data"
              >
                <FlotoNoData
                  v-if="showNoData && optionsToDisplay.length <= 0"
                  size="small"
                />
              </slot>
            </slot>
          </FlotoScrollView>
        </slot>
        <slot name="after-menu"></slot>
      </div>
    </div>
  </MPopover>
</template>

<script>
import Throttle from 'lodash/throttle'
import FindIndex from 'lodash/findIndex'
import Mousetrap from 'mousetrap'
import Bus from '@utils/emitter'
import { getDropdownItemValue } from '@utils/value'
import { authComputed } from '@state/modules/auth'
import DropdownTrigger from './dropdown-trigger'
import MultipleTrigger from './tree-picker/multiple-trigger'

export default {
  name: 'FlotoDropdownPicker',
  components: { DropdownTrigger, MultipleTrigger },
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    allowClear: { type: Boolean, default: true },
    mandatorySelection: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    searchFn: { type: Function, default: undefined },
    transformOptionsFn: { type: Function, default: undefined },
    textOnly: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    useMselect: { type: Boolean, default: false },
    defaultOpen: { type: Boolean, default: false },
    asInput: { type: Boolean, default: false },
    // eslint-disable-next-line
    size: { type: String, default: undefined },
    value: {
      type: [Number, String, Object, Array, Boolean],
      default: undefined,
    },
    disabled: { type: Boolean, default: false },
    asTag: { type: Boolean, default: false },
    placeholder: { type: String, default: undefined },
    inputClasses: { type: [String, Object, Array], default: undefined },
    immediateSearch: { type: Boolean, default: false },
    keepUnassigned: { type: Boolean, default: false },
    // on create form if value is archived then set default value
    validateArchivedValue: { type: Boolean, default: false },
    hiddenOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    visibleOptionsKeys: {
      type: Array,
      default() {
        return []
      },
    },
    avoidKeyboardNavigation: { type: Boolean, default: false },
    // eslint-disable-next-line
    showNoData: { type: Boolean, default: true },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    this.getDropdownItemValue = getDropdownItemValue
    // @TODO make this classes dynamic somehow
    this.menuClass = 'ant-dropdown-menu'
    this.menuItemClass = 'ant-dropdown-menu-item'
    this.menuItemDisabledClass = 'ant-dropdown-menu-item-disabled'
    this.menuItemSelectedClass = 'ant-dropdown-menu-item-selected'
    let initialIndex
    if (this.avoidKeyboardNavigation) {
      initialIndex = undefined
    } else {
      initialIndex = FindIndex(this.options, { key: this.value })
    }
    return {
      currentIndex: initialIndex === -1 ? 0 : initialIndex,
      isDropdownOpen: false,
      loadingOptions: false,
      searchQuery: '',
      searchedOptions: null,
    }
  },
  computed: {
    ...authComputed,
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    optionsToDisplay() {
      let options = this.optionsWithoutArchived
      const value = this.multiple ? this.value || [] : this.value
      if (this.filteredOptions) {
        options = this.filteredOptions
      }
      if (Array.isArray(value) && value.length) {
        options = options.filter(
          (o) => value.indexOf(o.key || o.id || o.value) === -1
        )
      }
      return options
    },
    optionsWithoutArchived() {
      return this.innerOptions.filter((o) => o.archived !== true)
    },
    filteredOptions() {
      if (this.searchQuery) {
        return this.optionsWithoutArchived.filter(
          (o) =>
            (o.text || o.name || o.label)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) >= 0
        )
      }
      return undefined
    },
    innerOptions() {
      let options = []
      if (this.searchedOptions) {
        options = this.searchedOptions
        if (this.hiddenOptionsKeys.length) {
          options = options.filter(
            (o) =>
              this.hiddenOptionsKeys.indexOf(getDropdownItemValue(o)) === -1
          )
        }
        if (this.visibleOptionsKeys.length) {
          options = options.filter(
            (o) => this.visibleOptionsKeys.indexOf(getDropdownItemValue(o)) >= 0
          )
        }
      } else {
        options = this.$attrs.options || this.options || []
        if (this.multiple && this.keepUnassigned === false) {
          options = options.filter((o) => getDropdownItemValue(o))
        }
        if (this.$attrs['additional-options']) {
          options = (this.$attrs['additional-options'] || []).concat(options)
        }
        if (this.mandatorySelection) {
          options = options.filter((o) => getDropdownItemValue(o) !== 0)
        }
        if (this.hiddenOptionsKeys.length) {
          options = options.filter(
            (o) =>
              this.hiddenOptionsKeys.indexOf(getDropdownItemValue(o)) === -1
          )
        }
        if (this.visibleOptionsKeys.length) {
          options = options.filter(
            (o) => this.visibleOptionsKeys.indexOf(getDropdownItemValue(o)) >= 0
          )
        }
      }
      // make archived options disabled
      if (this.multiple) {
        options = options.map((o) => ({
          ...o,
          disabled: o.archived || o.disabled,
        }))
      }
      return options
    },
    selectedItem() {
      if (this.innerOptions.length) {
        if (this.multiple) {
          return (this.value || []).map((id) =>
            this.innerOptions.find((o) => {
              const optionKey = getDropdownItemValue(o)
              return optionKey === id
            })
          )
        }
        return (this.innerOptions || []).find((p) => {
          const optionKey = p.id || p.value || p.key
          return optionKey === this.value || optionKey === parseInt(this.value)
        })
      }
      return undefined
    },
    selectedItemFormMultiple() {
      if (this.multiple && (this.selectedItem || []).length) {
        return this.selectedItem
          .filter((e) => e)
          .map((i) => getDropdownItemValue(i))
      }
      return []
    },
    listeners() {
      const { change, hide, show, click, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { options, ...attrs } = this.$attrs
      return attrs
    },
  },
  watch: {
    value: {
      handler(newValue, prevValue) {
        if (newValue && newValue !== prevValue) {
          this.searchItems()
        }
      },
    },
    options(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.dispatchEvent(new Event('resize'))
      }
    },
  },
  created() {
    this.upHandler = this.upHandler.bind(this)
    this.downHandler = this.downHandler.bind(this)
    this.enterHandler = this.enterHandler.bind(this)
    this.keyupHandler = this.keyupHandler.bind(this)

    if (this.immediateSearch) {
      this.searchItems()
    }
    if (this.value) {
      this.searchItems()
    }
    this.searchItems = Throttle(this.searchItems, 750)
    // set dynamic watch on value for handle archived value validation
    if (this.validateArchivedValue && !this.multiple && !this.immediateSearch) {
      this.$watch('value', {
        immediate: true,
        handler(newValue) {
          if (
            newValue &&
            (this.innerOptions.filter((o) => o.key === newValue).length === 0 ||
              this.innerOptions.find((o) => o.key === newValue).archived)
          ) {
            this.$nextTick(() => {
              let defaultValue = this.innerOptions.find((v) => v.default)
              if (!defaultValue) {
                defaultValue = { key: this.unassignedValue }
              } else if (!defaultValue && this.$attrs.mandatory) {
                defaultValue = this.innerOptions[0]
              }
              if (defaultValue) {
                this.$emit('change', defaultValue.key)
                this.$emit('blur')
              }
            })
          }
        },
      })
    }
    if (this.focusEventBrodcast) {
      const openPopover = (id) => {
        if (id === this.$attrs.id) {
          this.handleShow(true)
        } else {
          this.handleHide(true)
        }
      }
      const closePopover = (id) => {
        if (id === this.$attrs.id) {
          this.handleHide(true)
        }
      }
      Bus.$on('app:popover:broadcast:open', openPopover)
      Bus.$on('app:popover:broadcast:close', closePopover)
      this.$once('hook:beforeDestroy', () => {
        Bus.$off('app:popover:broadcast:open', openPopover)
        Bus.$off('app:popover:broadcast:close', closePopover)
      })
    }
  },
  mounted() {
    if (!this.avoidKeyboardNavigation) {
      this.$once('hook:beforeDestroy', () => {
        this.unbindEvents()
      })
    }
  },
  methods: {
    bindEvents() {
      if (this.avoidKeyboardNavigation) {
        return
      }
      Mousetrap.unbind(['up', 'down', 'enter'])
      document.addEventListener('keyup', this.keyupHandler)
    },
    keyupHandler(e) {
      switch (e.key) {
        case 'ArrowUp':
          this.upHandler(e)
          break
        case 'ArrowDown':
          this.downHandler(e)
          break
        case 'Enter':
          this.enterHandler(e)
          break
        default:
          break
      }
    },
    unbindEvents() {
      if (this.avoidKeyboardNavigation) {
        return
      }
      document.removeEventListener('keyup', this.keyupHandler)
      Mousetrap.bind(['up', 'down', 'enter'], (...args) => {
        const command =
          args[0].key === 'ArrowUp'
            ? 'move_up'
            : args[0].key === 'ArrowDown'
            ? 'move_down'
            : 'enter'
        Bus.$emit(command, ...[...args, command])
      })
    },
    resetCurrentIndex() {
      if (this.avoidKeyboardNavigation) {
        return
      }
      const initialIndex = FindIndex(this.optionsToDisplay, { key: this.value })
      this.currentIndex = initialIndex === -1 ? 0 : initialIndex
      this.$emit('active-item-index-change', this.currentIndex)
    },
    scrollActiveItemToView() {
      if (
        this.$refs.menuContainer &&
        this.$refs.menuContainer.$el.offsetHeight >
          this.$refs.scrollContainer.offsetHeight
      ) {
        const activeMenuItemDom =
          this.$refs.menuContainer.$el.childNodes[this.currentIndex]
        if (activeMenuItemDom) {
          activeMenuItemDom.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }
    },
    handleHide(skipBroadcast = false) {
      this.$emit('hide')
      this.unbindEvents()
      if (this.$refs.dropdownPopoverRef) {
        this.$refs.dropdownPopoverRef.hide()
      }
      setTimeout(() => {
        this.isDropdownOpen = false
      }, 350)
      if (this.focusEventBrodcast && this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:close', this.$attrs.id)
      }
    },
    handleShow(skipBroadcast = false) {
      if (this.disabled) {
        return
      }
      this.resetCurrentIndex()
      this.bindEvents()
      this.$emit('show')
      this.isDropdownOpen = true
      if (this.focusEventBrodcast && this.$attrs.id && !skipBroadcast) {
        Bus.$emit('app:single:dropdown:open', this.$attrs.id)
      }
      setTimeout(() => {
        this.$refs.searchBox && this.$refs.searchBox.focus()
      }, 100)
    },
    toggleDropdown() {
      if (this.isDropdownOpen) {
        this.handleHide()
      } else {
        this.handleShow()
      }
    },
    upHandler(e) {
      e.stopImmediatePropagation()
      e.preventDefault()
      e.stopPropagation()
      requestAnimationFrame(() => {
        this.currentIndex =
          (this.currentIndex + this.optionsToDisplay.length - 1) %
          this.optionsToDisplay.length
        this.$emit('active-item-index-change', this.currentIndex)
        this.scrollActiveItemToView()
      })
    },
    downHandler(e) {
      e.stopImmediatePropagation()
      e.preventDefault()
      e.stopPropagation()
      requestAnimationFrame(() => {
        this.currentIndex =
          (this.currentIndex + 1) % this.optionsToDisplay.length
        this.$emit('active-item-index-change', this.currentIndex)
        this.scrollActiveItemToView()
      })
      return true
    },
    enterHandler(e) {
      e.stopImmediatePropagation()
      e.stopPropagation()
      e.preventDefault()
      if (this.optionsToDisplay.length) {
        if (
          this.optionsToDisplay[this.currentIndex] &&
          this.optionsToDisplay[this.currentIndex].disabled
        ) {
          return
        }
        // here the item is selected
        requestAnimationFrame(() => {
          this.selectItem(this.optionsToDisplay[this.currentIndex])
        })
      }
    },
    popupContainer() {
      const element = this.$attrs['get-popup-container']
        ? this.$attrs['get-popup-container']()
        : this.focusEventBrodcast
        ? this.$el.closest('.single-control')
        : this.$el.closest('.__panel')
      if (element) {
        return element
      }
      return document.body
    },
    // for form validation dummy change event call through refs
    dummyChange() {
      this.$emit('change', '')
    },
    searchItems() {
      if (this.searchFn) {
        this.loadingOptions = true
        let selectedItems = []
        if (this.selectedItem) {
          if (this.multiple) {
            selectedItems = this.selectedItem.filter(Boolean)
          } else {
            selectedItems = [this.selectedItem]
          }
        }
        this.searchFn(this.searchQuery, selectedItems)
          .then((data) => {
            let additionalOptions = []
            if (this.$attrs['additional-options']) {
              additionalOptions = this.$attrs['additional-options'] || []
            }

            this.searchedOptions = additionalOptions.concat(
              this.transformOptionsFn ? this.transformOptionsFn(data) : data
            )
          })
          .finally(() => (this.loadingOptions = false))
      }
    },
    handleChange(value) {
      if (this.multiple) {
        this.$emit(
          'selected',
          value.map((id) =>
            this.innerOptions.find(
              (o) => o.key === id || o.id === id || o.value === id
            )
          )
        )
        // If template picker and needs custom event for template selection
        if (this.selectedEventName) {
          this.$emit(
            this.selectedEventName,
            value.map((id) =>
              this.innerOptions.find(
                (o) => o.key === id || o.id === id || o.value === id
              )
            )
          )
        }
        this.$emit('change', value)
        this.$emit('blur')
      } else {
        this.$emit(
          'selected',
          this.innerOptions.find(
            (o) => o.key === value || o.id === value || o.value === value
          )
        )
        // If template picker and needs custom event for template selection
        if (this.selectedEventName) {
          this.$emit(
            this.selectedEventName,
            this.innerOptions.find(
              (o) => o.key === value || o.id === value || o.value === value
            )
          )
        }
        const finalValue =
          value || (value === 0 || value === '' ? value : this.unassignedValue)
        this.$emit('change', finalValue)
        this.$emit('blur')
        this.searchQuery = ''
      }
    },
    selectItem(item) {
      const value = this.multiple ? this.value || [] : this.value
      if (Array.isArray(value)) {
        if (value.indexOf(item.key) === -1) {
          this.handleChange([...value, item.value || item.id || item.key])
        } else {
          this.handleChange(
            value.filter((i) => i !== item.value || item.id || item.key)
          )
        }
      } else {
        this.handleChange(item.value || item.id || item.key)
        this.hide()
      }
    },
    show() {
      this.handleShow()
    },
    hide() {
      this.handleHide()
    },
  },
}
</script>

<style lang="less">
.scroll-dropdown-menu-item {
  a {
    color: inherit;
  }
}
</style>
<style lang="less" scoped>
.dropdown-with-colors {
  .dropdown-item {
    @apply flex items-center cursor-pointer;
  }
}
</style>
