<template>
  <FlotoDropdownPicker
    :value="value"
    as-input
    :options="options"
    :placeholder="$tc('select')"
    class="w-full"
    :allow-clear="false"
    @change="$emit('change', $event)"
  >
    <template v-slot:menu-item="{ item: option, selectItem }">
      <div
        class="dropdown-item flex items-center"
        @click.stop="selectItem(option)"
      >
        <img :src="flagMaps[option.flagKey]" />
        <span class="ml-2 text-ellipsis" style="display: block">
          {{ option.name }}
        </span>
      </div>
    </template>
  </FlotoDropdownPicker>
</template>

<script>
import { getEnabledLanguagesApi } from '@modules/support-console/api/language-preference-api'
import { LANGUAGE_MAP } from '@utils/language-map'
import IN from '@assets/images/flags/in.png'
import US from '@assets/images/flags/us.png'
import PH from '@assets/images/flags/ph.png'
import MY from '@assets/images/flags/my.png'
import TH from '@assets/images/flags/th.png'
import ID from '@assets/images/flags/id.png'
import AR from '@assets/images/flags/ar.png'
import FR from '@assets/images/flags/fr.png'
import ES from '@assets/images/flags/es.png'
import PT from '@assets/images/flags/pt.png'

export default {
  name: 'FlotoLanguageSelector',
  model: {
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      lang: this.value,
    }
  },
  computed: {
    flagMaps() {
      return {
        en: US,
        gu: IN,
        hi: IN,
        tl: PH,
        ms: MY,
        th: TH,
        id: ID,
        ar: AR,
        fr: FR,
        es: ES,
        pt: PT,
      }
    },
  },
  created() {
    getEnabledLanguagesApi().then((data) => {
      this.options = data.map((o) => {
        const lang = LANGUAGE_MAP[o.key]
        return {
          name: o.value,
          text: o.value,
          value: o.key,
          key: o.key,
          flagKey: lang,
        }
      })
    })
  },
}
</script>
